import { Preferences } from '@capacitor/preferences';

export const clearWorldCacheKeys = async () => {
  const { keys } = await Preferences.keys();
  const worldKeys = keys.filter((key) => key.includes('world'));

  await Promise.all(worldKeys.map((key) => Preferences.remove({ key })));
};

export const clearCharacterCacheKeys = async () => {
  const { keys } = await Preferences.keys();
  const worldKeys = keys.filter((key) => key.includes('character'));

  await Promise.all(worldKeys.map((key) => Preferences.remove({ key })));
};
